.cursor {
  background-color: rgba(0, 0, 0, 0);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  color: black;
  font-size: 10px;
  display: none;
  align-items: center;
  justify-content: center;
}

.cursor i {
  font-size: 40px;
  color: black;
  text-align: center;
  pointer-events: none;
}
.cursor.target_cursor {
  mix-blend-mode: normal;
  background-color: black;
  color: white;
}
.cursor.target_cursor i {
  color: white;
}
.cursor.target_cursor p {
  color: white;
  font-size: 16px;
}
@media (min-width: 1024px) {
  .cursor {
    display: flex;
  }
}
