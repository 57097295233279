.header {
  width: 100%;
  height: 100vh;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 20% 0%;
}
.header .title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  overflow: hidden;
  height: fit-content;
}
.header .title img {
  width: 100%;
}
.header .content {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 50%;
  transform: translateY(-50%);
}
.header .content p {
  color: white;
  text-shadow:
    -2px -2px 0 black,
    2px -2px 0 black,
    -2px 2px 0 black,
    2px 2px 0 black;
  font-size: 15px;
}

.header .title h1 {
  color: white;
  font-size: 30px;
}

@media (min-width: 576px) {
  .header .content {
    width: 50%;
  }
  .header .content p {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .header .content {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .header .content {
    width: 30%;
  }
  .header .content p {
    font-size: 30px;
  }
}

@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
  .header .content p {
    font-size: 40px;
  }
}
