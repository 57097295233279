.schedule {
  margin-top: 50px;
  padding: 20px;
  height: max-content;
}
.schedule .table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  text-align: center;
}
.schedule .row {
  display: contents;
  border: 0;
}
.schedule .row .top {
  background-color: white;
  border: 0;
}
.schedule .cellHeader {
  font-weight: bold;
  padding: 10px;
  background-color: #941c13;
  color: white;
}
.schedule .cell {
  padding: 10px;
  border-bottom: 1px solid #941c13;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.schedule .cellHr {
  padding: 10px;
  font-weight: bold;
  min-height: 50px;
  background-color: #941c13;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.schedule .classContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.schedule .classInfo {
  font-size: 14px;
  text-align: center;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #1a1a4b;
}
.schedule .classInfo strong {
  font-size: 16px;
}
