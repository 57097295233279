.accordionSchedule {
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.accordionSchedule .accordionItem {
  border-bottom: 1px solid #ddd;
}

.accordionSchedule .accordionHeader {
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  background-color: #941c13;
  color: #fff;
}

.accordionSchedule .accordionHeader:hover {
  background-color: #c72b1e;
}

.accordionSchedule .accordionContent {
  padding: 10px 15px;
  background-color: #f9f9f9;
  overflow: hidden;
  display: none;
}
.accordionSchedule .accordionContent.show {
  display: block;
}

.accordionSchedule .classItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.accordionSchedule .time {
  width: 70px;
  font-weight: bold;
  color: #333;
}

.accordionSchedule .info {
  flex-grow: 1;
  padding-left: 10px;
  color: #000;
}
