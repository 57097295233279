.tariff {
  height: max-content;
  background-color: white;
  padding-top: 100px;
}
.tariff_title {
  font-size: 60px;
  text-align: center;
  text-transform: uppercase;
}
.tariff .Tarif_table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}
.tariff .Tarif_table .column {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 40px 0;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 10px 10px 10px 0;
  align-self: center;
}
.tariff .Tarif_table .column.sub {
  width: 60%;
  align-self: right;
}
.tariff .Tarif_table .title {
  width: fit-content;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.tariff .Tarif_table .price {
  width: max-content;
  font-size: 20px;
}

@media (min-width: 768px) {
  .tariff {
    height: max-content;
    background-color: white;
  }
}
@media (min-width: 1024px) {
  .tariff {
    height: max-content;
    background-color: white;
    width: 100%;
  }
  .tariff_title {
    font-size: 60px;
    text-align: center;
    text-transform: uppercase;
  }
  .tariff .Tarif_table {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .tariff .Tarif_table .column {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 40px 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    padding: 10px 10px 10px 0;
    align-self: center;
  }
  .tariff .Tarif_table .column.sub {
    width: 25%;
    align-self: right;
  }
  .tariff .Tarif_table .title {
    width: fit-content;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .tariff .Tarif_table .price {
    width: max-content;
    font-size: 30px;
  }
}
