.loader {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader.hidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
  display: none;
}
