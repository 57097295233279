.nav {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 20px;
  background-color: transparent;
  color: white;
  z-index: 100;
}
.nav .nav_logo {
  width: 200px;
  height: 100px;
}
