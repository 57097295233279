.layout-gallery {
  height: fit-content;
  padding: 200px 0 0;
}
.layout-gallery .title {
  font-size: 50px;
  margin-left: 20px;
}
.layout-gallery .context {
  font-size: 20px;
  margin: 150px 15% 100px 15%;
  width: 70%;
  text-align: justify;
}
.layout-gallery .Photo {
  margin: 50px 0;
}
.layout-gallery .Photo p {
  font-size: 20px;
  transition: 0.5s;
}
.layout-gallery .Photo img {
  width: 100%;
}

@media (min-width: 576px) {
  .layout-gallery {
    height: max-content;
    padding: 200px 0;
  }
  .layout-gallery .title {
    font-size: 80px;
    margin-left: 20px;
  }
  .layout-gallery .context {
    font-size: 20px;
    width: 70%;
    text-align: justify;
  }
  .layout-gallery .photos {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }
  .layout-gallery .Photo {
    width: 30%;
    height: 50vh;
  }
  .layout-gallery .Photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .layout-gallery .Photo p {
    font-size: 20px;
    color: black;
  }
}
@media (min-width: 1440px) {
  .layout-gallery {
    height: max-content;
    padding: 200px 0;
    width: 100%;
  }
  .layout-gallery .title {
    font-size: 120px;
    margin-left: 220px;
  }
  .layout-gallery .context {
    font-size: 30px;
    margin: 150px 10% 0 60%;
    width: 30%;
    text-align: justify;
  }
  .layout-gallery .photos {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }
  .layout-gallery .Photo {
    width: 30%;
    height: 50vh;
  }
  .layout-gallery .Photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .layout-gallery .Photo p {
    font-size: 20px;
    color: black;
  }
}
