.footer {
  height: max-content;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.footer .content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.footer .content p {
  font-size: 0.5rem;
  padding: 5px 0;
}
.footer .content a {
  display: block;
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #000;
  font-size: 0.5rem;
  padding: 5px 0;
  width: 50%;
}
.footer .content .title {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}
.footer .policy {
  font-size: 0.5rem;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
}

@media (min-width: 768px) {
  .footer {
    height: max-content;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .footer .content {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .footer .content .sociales {
    width: 30%;
  }
  .footer .content .content-Contact {
    width: 30%;
  }
  .footer .content .times {
    width: 30%;
  }
  .footer .content p {
    font-size: 1rem;
    padding: 5px 0;
  }
  .footer .content a {
    display: block;
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid #000;
    font-size: 1.5rem;
    padding: 5px 0;
    width: 50%;
  }
  .footer .content .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
  }
  .footer .policy {
    font-size: 1rem;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 50px;
  }
}
