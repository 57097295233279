.about {
  background-color: #fff;
  transition: 0.8s ease;
}
.about .about-one {
  height: max-content;
  transform: translateY(150px);
  overflow: hidden;
}
.about .about-one .content {
  z-index: 2;
}
.about .about-text {
  font-size: 2rem;
  text-align: center;
}
.about .about-one .content .title {
  font-size: 1rem;
  width: 80%;
  font-weight: 600;
  color: black;
  margin: 100px auto;
}
.about .about-one .photo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
}
.about .about-one .photo img {
  width: 70%;
  height: auto;
  filter: brightness(100%);
}

/* two */
.about .about-two {
  height: max-content;
}
.about .about-two p {
  margin: 200px 0 100px 0;
  font-size: 2rem;
}
.about .about-two .photos {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.about .about-two .photos .photo {
  width: 100%;
  display: flex;
  justify-content: center;
}
.about .about-two .photos .photo img {
  width: 80%;
  height: auto;
}

/* three */
.about .about-three {
  height: max-content;
}
.about .about-three .content {
  z-index: 2;
  width: 100%;
}
.about .about-three .content .title {
  font-size: 2rem;
  color: black;
  margin: 100px 0 100px 0;
}
.about .about-three .content .conetent-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15%;
}
.about .about-three .content .conetent-text p {
  width: 90%;
  margin-top: 50px;
  font-size: 0.8rem;
  color: rgb(41, 41, 41);
}

/* five */
.about .about-five {
  height: max-content;
  display: flex;
  margin-top: 100px;
}
.about .about-five .sticky {
  width: 30%;
  position: sticky;
  top: 10%;
  display: none;
  flex-direction: column;
  align-items: left;
  height: 100vh;
  margin-left: 5%;
}
.about .about-five .content {
  margin-top: 40%;
  width: 100%;
  /* margin-left: 30%; */
}
.about .about-five .element {
  position: relative;
}

.about .about-five .sticky .title {
  font-size: 3rem;
  margin: 0 0 40% 0;
}
.about .about-five .sticky .trainer-name {
  font-size: 1.5rem;
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  transition: transform 0.5s ease;
  margin-left: 10%;
}
.about .about-five .sticky .trainer-name.active {
  color: rgba(0, 0, 0, 1);
  transform: translateX(5%);
  transition: transform 0.5s ease;
}
.about .about-five .sticky .trainer-name::after {
  content: "";
  position: absolute;
  width: 5%;
  background-color: black;
  height: 2px;
  top: 50%;
  left: -6%;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}
.about .about-five .sticky .trainer-name.active::after {
  transform: scaleX(1);
}

.about .about-five .content .trainer {
  display: flex;
  flex-direction: column;
}
.about .about-five .content .trainer .photo {
  width: 100%;
  display: flex;
  justify-content: end;
}
.about .about-five .content .trainer .photo img {
  width: 50%;
  height: auto;
}
.about .about-five .content .description .name {
  top: -10vh;
  position: absolute;
  display: inline;
  font-size: 3rem;
}
.about .about-five .content .description .name p {
  display: inline-block;
}
.about .about-five .content .description .text {
  margin: 30px 5% 150px;
  font-size: 0.8rem;
  width: 90%;
}

@media (min-width: 768px) {
  .about .about-text {
    font-size: 2.5rem;
  }
  .about .about-one .content .title {
    font-size: 1.5rem;
  }
  .about .about-two p {
    font-size: 2.5rem;
  }
  .about .about-three .content .title {
    font-size: 2.5rem;
  }
  .about .about-three .content .conetent-text p {
    font-size: 1.2rem;
  }
  .about .about-five {
    margin-top: 0px;
  }
  .about .about-five .content .description .name {
    font-size: 3rem;
  }
  .about .about-five .content .description .text {
    font-size: 1.2rem;
  }
}
@media (min-width: 1024px) {
  .about .about-three .content .conetent-text {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
  }
  .about .about-three .content .conetent-text p {
    width: 40%;
    margin-top: 50px;
    font-size: 0.8rem;
    color: rgb(41, 41, 41);
    text-align: justify;
  }

  .about .about-five {
    margin-top: 100px;
  }
  .about .about-five .sticky {
    display: flex;
  }
  .about .about-five .content {
    margin-top: 40%;
    width: 60%;
    margin-left: 10%;
  }

  .about .about-five .sticky .title {
    font-size: 2.5rem;
  }
  .about .about-five .content .trainer .photo {
    width: 80%;
  }
  .about .about-five .content .description .name {
    top: -70px;
    font-size: 4rem;
    left: -50px;
  }
  .about .about-five .content .description .text {
    margin: 50px 0 150px;
    font-size: 1rem;
    width: 80%;
  }
}
@media (min-width: 1440px) {
  .about {
    background-color: #fff;
    transition: 0.8s ease;
  }
  .about .about-one {
    height: max-content;
    transform: translateY(150px);
    overflow: hidden;
  }
  .about .about-one .content {
    z-index: 2;
  }
  .about .about-text {
    font-size: 2.5rem;
  }
  .about .about-one .content .title {
    font-size: 2rem;
    width: max-content;
    font-weight: 600;
    color: black;
    margin: 200px auto;
  }
  .about .about-one .photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
  }
  .about .about-one .photo img {
    width: 70%;
    height: max-content;
    filter: brightness(100%);
  }

  /* two */
  .about .about-two {
    height: max-content;
  }
  .about .about-two p {
    margin: 100px 0 100px 0;
    font-size: 2.5rem;
  }
  .about .about-two .photos {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .about .about-two .photos .photo {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .about .about-two .photos .photo img {
    width: 30%;
    height: auto;
  }

  /* three */
  .about .about-three {
    height: max-content;
  }
  .about .about-three .content {
    z-index: 2;
    width: 100%;
  }
  .about .about-three .content .title {
    font-size: 3rem;

    color: black;
    margin: 100px 0 100px 0;
  }
  .about .about-three .content .conetent-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 15%;
  }
  .about .about-three .content .conetent-text p {
    width: 40%;
    margin-top: 50px;
    font-size: 1.3rem;
    color: rgb(41, 41, 41);
  }

  /* five */
  .about .about-five {
    height: max-content;
    display: flex;
    margin-top: 200px;
  }
  .about .about-five .sticky {
    width: 30%;
    position: sticky;
    top: 10%;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 100vh;
    margin-left: 5%;
  }
  .about .about-five .content {
    margin-top: 40%;
    width: 60%;
  }
  .about .about-five .element {
    position: relative;
  }

  .about .about-five .sticky .title {
    font-size: 3rem;
    margin: 0 0 40% 0;
  }
  .about .about-five .sticky .trainer-name {
    font-size: 1.5rem;
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.4);
    position: relative;
    transition: transform 0.5s ease;
    margin-left: 10%;
  }
  .about .about-five .sticky .trainer-name.active {
    color: rgba(0, 0, 0, 1);
    transform: translateX(5%);
    transition: transform 0.5s ease;
  }
  .about .about-five .sticky .trainer-name::after {
    content: "";
    position: absolute;
    width: 5%;
    background-color: black;
    height: 2px;
    top: 50%;
    left: -6%;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease;
  }
  .about .about-five .sticky .trainer-name.active::after {
    transform: scaleX(1);
  }

  .about .about-five .content .trainer {
    display: flex;
    flex-direction: column;
  }
  .about .about-five .content .trainer .photo {
    width: 60%;
    display: flex;
    justify-content: end;
  }
  .about .about-five .content .trainer .photo img {
    width: 60%;
    height: auto;
  }
  .about .about-five .content .description .name {
    top: -14vh;
    position: absolute;
    font-size: 6rem;
    left: -150px;
  }
  .about .about-five .content .description .name span {
    font-size: 3rem;
    font-weight: 300;
  }
  .about .about-five .content .description .text {
    margin: 80px 0 200px;
    font-size: 1.2rem;
    width: 60%;
  }
}
