.GymGallery {
  margin-top: 150px;
  margin-bottom: 150px;
}
.GymGallery .title {
  font-size: 40px;
  margin-left: 0px;
}
.GymGallery .content {
  font-size: 20px;
  margin: 150px auto;
  width: 80%;
  text-align: justify;
}
.GymGallery .gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.GymGallery .gallery .photo {
  width: 90%;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
}
.GymGallery .gallery .photo img {
  width: 100%;
}
@media (min-width: 768px) {
  .GymGallery {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  .GymGallery .title {
    font-size: 120px;
    margin-left: 220px;
  }
  .GymGallery .content {
    font-size: 20px;
    margin: 150px auto;
    width: 30%;
    text-align: justify;
  }
  .GymGallery .gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .GymGallery .gallery .photo {
    width: 45%;
    margin: 0 10px;
    position: relative;
    overflow: hidden;
  }
  .GymGallery .gallery .photo img {
    width: 100%;
  }
}
