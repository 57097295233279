.layout_fitnessClasses {
  height: 400vh;
  background-color: white;
  width: 100%;
}
.layout_fitnessClasses .sticky .image {
  position: absolute;
  height: 100%;
  width: 100%;
}
.layout_fitnessClasses .sticky .image .shadow {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.788);
  z-index: 1;
  pointer-events: none;
}
.layout_fitnessClasses .sticky .image img {
  position: absolute;
  z-index: -10;
  height: 100%;
  width: 100%;
  object-fit: cover;
  scale: 0.5;
  filter: brightness(80%);
}
.layout_fitnessClasses .sticky {
  position: sticky;
  overflow: hidden;
  top: 0;
  z-index: 10;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.layout_fitnessClasses .content {
  text-align: center;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 40px 0;
  width: 100%;
  position: relative;
  gap: 60%;
}
.layout_fitnessClasses .content .title {
  /* display: none; */
  width: 20%;
  font-size: 30px;

  color: white;
  font-weight: bold;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
}
.layout_fitnessClasses .content .title p {
  mix-blend-mode: difference;
}
.layout_fitnessClasses .content .title.first {
  width: fit-content;
}
.layout_fitnessClasses .content .title.second {
  width: fit-content;
}
.layout_fitnessClasses .content .sub {
  margin-top: 30px;
  font-size: 30px;
  color: black;
}

.layout_fitnessClasses .marque {
  display: none;
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  /* background-color: rgba(197, 24, 24, 0.884); */
}
.layout_fitnessClasses .marque p {
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
  color: white;
}
.layout_fitnessClasses .marque.one {
  bottom: 0px;
}
.layout_fitnessClasses .marque.two {
  bottom: 40px;
}
.layout_fitnessClasses .marque span {
  font-style: italic;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .layout_fitnessClasses .content .title {
    width: 20%;
    font-size: 40px;
  }

  .layout_fitnessClasses .content .sub {
    margin-top: 30px;
    font-size: 30px;
    color: black;
  }

  .layout_fitnessClasses .marque {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1024px) {
  .layout_fitnessClasses .content .title {
    width: 20%;
    font-size: 60px;
  }
}

@media (min-width: 1440px) {
  .layout_fitnessClasses .content .title {
    width: 20%;
    font-size: 80px;
  }
}
@media (min-width: 1600px) {
  .layout_fitnessClasses {
    height: 400vh;
    background-color: white;
    width: 100%;
  }
  .layout_fitnessClasses .sticky .image {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .layout_fitnessClasses .sticky .image .shadow {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.788);
    z-index: 1;
    pointer-events: none;
  }
  .layout_fitnessClasses .sticky .image img {
    position: absolute;
    z-index: -10;
    height: 100%;
    width: 100%;
    object-fit: cover;
    scale: 0.5;
    filter: brightness(80%);
  }
  .layout_fitnessClasses .sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .layout_fitnessClasses .content {
    text-align: center;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 40px 0;
    width: 100%;
    position: relative;
    gap: 60%;
  }
  .layout_fitnessClasses .content .title {
    width: 20%;
    font-size: 100px;
    color: white;
    font-weight: bold;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
      1px 1px 0 black;
  }
  .layout_fitnessClasses .content .title p {
    mix-blend-mode: difference;
  }
  .layout_fitnessClasses .content .title.first {
    width: fit-content;
  }
  .layout_fitnessClasses .content .title.second {
    width: fit-content;
  }
  .layout_fitnessClasses .content .sub {
    margin-top: 30px;
    font-size: 30px;
    color: black;
  }

  .layout_fitnessClasses .marque {
    /* display: none; */
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
    /* background-color: rgba(197, 24, 24, 0.884); */
  }
  .layout_fitnessClasses .marque p {
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
      1px 1px 0 black;
    color: white;
  }
  .layout_fitnessClasses .marque.one {
    bottom: 0px;
  }
  .layout_fitnessClasses .marque.two {
    bottom: 40px;
  }
  .layout_fitnessClasses .marque span {
    font-style: italic;
  }
}
@media (min-width: 2100px) {
}
