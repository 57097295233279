.events {
  margin-top: 150px;
  height: max-content;
}
.events .events_title {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
}
.events .events_title .title {
  font-size: 4rem;
  font-weight: 600;
}
.events .events_title .text {
  font-size: 1.5rem;
  font-weight: 400;
  width: 30%;
  margin-top: 200px;
}
.events .acordion {
  margin: 80px 0;
}
