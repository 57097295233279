.layout-info {
  height: fit-content;
  position: relative;
  overflow: hidden;
}

.layout-info .card {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 50px 0 50px 0;
}
.layout-info .rev {
  flex-direction: row;
}
.layout-info .rev .card_wraper {
  margin-right: 20px;
}
.layout-info .rev .btn {
  display: block;
  text-align: right;
  margin-top: 20px;
}
.layout-info .rev .title {
  text-align: right;
}
.layout-info .rev .content {
  text-align: right;
}
.layout-info .card .card_wraper {
  width: 100%;
  margin-left: 20px;
}
.layout-info .card .btn {
  display: block;
  margin-top: 20px;
}
.layout-info .card .card_wraper .title {
  font-size: 40px;
  margin-bottom: 50px;
}
.layout-info .card .card_wraper .content {
  font-size: 16px;
}
.layout-info .card .card-img {
  display: none;
  width: 60%;
  position: relative;
}
.layout-info .card .card-img img {
  width: max-content;
  height: 200px;
  margin-left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 0px 100px 66px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 100px 66px rgba(0, 0, 0, 0.37);
  box-shadow: 30px 10px 100px 40px rgba(0, 0, 0, 0.35);
}

@media (min-width: 1024px) {
  .layout-info .card .btn {
    display: none;
  }
  .layout-info .rev .btn {
    display: none;
  }
  .layout-info .card {
    height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0;
  }
  .layout-info .rev {
    flex-direction: row;
  }
  .layout-info .rev .card_wraper {
    margin-right: 50px;
  }
  .layout-info .rev .title {
    text-align: right;
  }
  .layout-info .rev .content {
    text-align: right;
  }
  .layout-info .card .card_wraper {
    width: 30%;
    margin-left: 50px;
  }
  .layout-info .card .card_wraper .title {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .layout-info .card .card_wraper .content {
    font-size: 16px;
  }
  .layout-info .card .card-img {
    display: block;
    width: 60%;
  }
  .layout-info .card .card-img img {
    width: max-content;
    height: 320px;
  }
}

@media (min-width: 1440px) {
  .layout-info .card .card-img img {
    width: max-content;
    height: 400px;
  }
  .layout-info .card .card_wraper .title {
    font-size: 50px;
    margin-bottom: 50px;
  }
  .layout-info .card .card_wraper .content {
    font-size: 20px;
  }
}
@media (min-width: 1600px) {
  .layout-info .card .card-img img {
    width: max-content;
    height: 520px;
  }
  .layout-info .card .card_wraper .title {
    font-size: 60px;
    margin-bottom: 50px;
  }
  .layout-info .card .card_wraper .content {
    font-size: 20px;
  }
}
