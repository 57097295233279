.fitnessClasses {
  height: max-content;
  margin-top: 120px;
  margin-bottom: 120px;
}
.fitnessClasses .title {
  font-size: 70px;
  margin-left: 60px;
}
.fitnessClasses .list {
  margin-top: 140px;
}
.fitnessClasses .list .item {
  padding: 20px 0;
  border-bottom: 2px solid #000;
  width: 90%;
  position: relative;
  margin-top: 30px;
}
.fitnessClasses .list .item .title {
  font-size: 20px;
  margin-left: 60px;
  margin-top: 20px;
  text-transform: uppercase;
}
.fitnessClasses .list .item .description {
  font-size: 10px;
  margin-left: 80px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .fitnessClasses {
    height: max-content;
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .fitnessClasses .title {
    font-size: 100px;
    margin-left: 60px;
  }
  .fitnessClasses .list {
    margin-top: 140px;
  }
  .fitnessClasses .list .item {
    padding: 20px 0;
    border-bottom: 2px solid #000;
    width: 90%;
    position: relative;
    margin-top: 30px;
  }
  .fitnessClasses .list .item .title {
    font-size: 60px;
    margin-left: 60px;
    margin-top: 20px;
    text-transform: uppercase;
  }
  .fitnessClasses .list .item .description {
    font-size: 18px;
    margin-top: 20px;
    margin-left: 80px;
  }
}
